/* eslint-disable prefer-destructuring */
import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Layout } from "../components/layout"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { formatDate } from "../components/formatDate"
import Img from "gatsby-image"
import { DividerBlock } from "../components/dividerBlock"
import { Credits } from "../components/credits"
import { useStripHTML } from "../helpers/use-strip-html"
import ReactPlayer from "react-player"
import ImageFadeIn from "react-image-fade-in"
dayjs.extend(utc)
dayjs.extend(timezone)

const Program = ({ data }) => {
  const sideBarText = "Public Program"
  const program = data.datoCmsSinglePublicProgram
  const hasGallery = program.imageGallery.length > 0
  const featuredImage = program.featuredImage

  return (
    <Layout
      bgcolor={program.backgroundColor}
      wrapperClass="public-program"
      accentColor={program.accentColor}
    >
      <HelmetDatoCms
        seo={program.seoMetaTags}
        title={useStripHTML(program.formattedTitle)}
      />
      <div className="container row">
        <div className="col-sm-2 col-xs-6 col">
          <Link
            to="/public-program"
            className="sidebar-text sidebar-text-first"
          >
            {sideBarText}
          </Link>
        </div>
        <div className="col-sm-9 col-xs-6 col">
          <h1
            className="gothic--xlarge large-title"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: program.formattedTitle,
            }}
          />
        </div>
      </div>
      <div className="program-main container row single-article-content">
        <div className="feature-image col-sm-9 col-xs-6 col">
          {program.featuredImage && (
            <div>
              <ImageFadeIn
								src={program.featuredImage.url}
								alt={program.featuredImage.alt}
							/>
              {program.featuredImage.title && (
                <div class="col-sm-14 col-xs-6">
                  <small
                    className="caption"
                    dangerouslySetInnerHTML={{
                      __html: program.featuredImage.title,
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="main-info col-sm-4 col-xs-6 offset-sm-1 col">
          <div className="intro-text">
            <div className="event-dates">
              <h4>
                {program.publicProgramType?.title}
                <br />
                {dayjs(program.event[0]?.performanceDate).format(
                  "dddd D MMMM YYYY, h:mma"
                )}
              </h4>
              <h4>{program.location?.title}</h4>
              <span>{program.location?.address}</span>
            </div>
            <div
              className="main-intro event-info"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: program.eventInformation,
              }}
            />
          </div>
          <div className="intro-divider">
            <DividerBlock accentColor={program.accentColor} />
          </div>
        </div>
        <div className="program-info row single-article-content">
          <div
            className="content program-description info col-sm-7 offset-sm-2 col-xs-6 col"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: program.description,
            }}
          />
					{program.downloadFile && (
						<div
						className="download-file col-sm-7 offset-sm-2 col-xs-6 col"
						>
							<Link to={program.downloadFile.url} target="_blank" rel="noreferrer" download={program.downloadText}>
								<svg width="40" height="40" viewBox="0 0 40 40" fill="none">
									<circle cx="20" cy="20" r="19" stroke="black" strokeWidth="2" />
									<path d="M11.5791 23.1578L20.0002 31.5789L28.4212 23.1578" fill="none" stroke="black" strokeWidth="2" />
									<path d="M20 7.36841L20 31.5789" fill="none" stroke="black" strokeWidth="2" />
								</svg>
								<div className="text-wrapper">
									<p style={{ marginBottom: '0', textDecoration: 'underline' }}>{program.downloadText}</p>
								</div>
							</Link>
						</div>
					)}
        </div>
      </div>

      {program.externalVideo && (
        <div className="external-video container row">
          <div className="programs-sidebar col-sm-2 col-xs-6 col">
            <span className="sidebar-text sidebar-text-everything">
              Event video
            </span>
          </div>
          <div className="container row col-sm-7 col-xs-6 col">
            <div className="video-wrapper">
              <ReactPlayer
                url={program.externalVideo.url}
                height="100%"
                width="100%"
                controls
                muted
                loop
                title={program.externalVideo.title}
                className="responsive-iframe"
              />
            </div>
          </div>
        </div>
      )}

      {program.audioRecording && (
        <div className="event-recording container row">
          <div className="programs-sidebar col-sm-2 col-xs-6 col">
            <span className="sidebar-text sidebar-text-everything">
              Event recording
            </span>
          </div>
          <div className="container col-sm-7 col-xs-6 col">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: program.audioRecording,
              }}
            />
          </div>
        </div>
      )}

      {program.associatedExhibition.length > 0 && (
        <div className="associated-exhibition container row">
          <div className="programs-sidebar col-sm-2 col-xs-6 col">
            <span className="sidebar-text sidebar-text-everything">
              Associated exhibition
            </span>
          </div>
          <div className="programs-content col col-sm-12">
            {program.associatedExhibition.map(
              ({
                id,
                formattedTitle,
                locations,
                excerpt,
                featuredImage,
                startDate,
                endDate,
                slug,
              }) => {
                const niceStartDate = formatDate(startDate)
                const niceEndDate = formatDate(endDate)
                return (
                  <div className="itr image-text-row" key={id}>
                    <div className="image-text-row-image-wrapper">
                      <Link to={`/exhibition/${slug}`}>
												<ImageFadeIn
													src={featuredImage.url}
													alt={featuredImage.alt}
												/>
                      </Link>
                    </div>
                    <div className="image-text-row-text-wrapper">
                      <Link to={`/exhibition/${slug}`}>
                        <h3
                          className="sans--medium"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: formattedTitle,
                          }}
                        />
                      </Link>
                      <p className="date-info-wrapper">
                        {locations.map(({ location }) => (
                          <span>{location.title}</span>
                        ))}
                        <br />
                        {`${niceStartDate.date} ${niceStartDate.monthLong}`}
                        &nbsp;-&nbsp;
                        {`${niceEndDate.date} ${niceEndDate.monthLong} ${niceEndDate.year}`}
                      </p>
                      {excerpt !== "" && (
                        <p
                          className="content excerpt"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: excerpt,
                          }}
                        />
                      )}
                      <Link className="read-more" to={`/exhibition/${slug}`}>
                        Read More
                      </Link>
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </div>
      )}

      {(program.credit || program.creditLogos.length > 0) && (
        <Credits
          creditText={program.credit}
          creditLogos={program.creditLogos}
        />
      )}

      <div className="col-sm-5 col-xs-4 offset-xs-1 offset-sm-3 col spacing-0">
        <DividerBlock accentColor={program.accentColor} />
      </div>
    </Layout>
  )
}

Program.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default Program

export const query = graphql`
  query PublicSingleProgramQuery($slug: String!) {
    datoCmsSinglePublicProgram(slug: { eq: $slug }) {
      id
      backgroundColor
      accentColor {
        hex
      }
      publicProgramType {
        title
      }
      featuredImage {
				url(imgixParams: { h: "800", ar: "5:3", fit: "crop", crop: "focalpoint" })
        title
        alt
      }
			downloadText
			downloadFile {
				url
			}
      imageGallery {
        title
        video {
          mp4Url
        }
        fluid(maxHeight: 750) {
          ...GatsbyDatoCmsSizes_noBase64
        }
      }
      eventInformation
      externalVideo {
        url
        providerUid
        title
      }
      audioRecording
      event {
        performanceDate
      }
      location {
        title
        address
      }
      description
      formattedTitle
      associatedExhibition {
        id
        featuredImage {
					url(imgixParams: { w: "600", ar: "5:3", fit: "crop", crop: "focalpoint" })

        }
        endDate
        startDate
        formattedTitle
        slug
        locations {
          location {
            title
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      creditLogos {
        alt
        fixed(height: 50) {
          ...GatsbyDatoCmsFixed_noBase64
        }
      }
      credit
    }
  }
`
